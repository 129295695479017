.certification-list-page {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  td.certification-list-page__cell--important,
  span.certification-list-page__cell--important {
    color: $information-dark;
    font-weight: bold;
  }
}
