.page-section {

  &__container {
    display: flex;
  }

  &__content {
    width: 50%;
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.target-profile__edit-form {

  #targetProfileName {
    margin-bottom: 24px;
  }
}

.target-profile_title {
  margin-right: 8px;
}
