.campaign-edit-form__checkbox {
  display: flex;
  align-items: center;

  & > input[type=checkbox] {
    margin: 0 8px;
    width: 16px;
    height: 16px;
  }
}
