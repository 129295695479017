.campaign__subtitle {
  font-size: 0.875rem;
  color: $grey-50;
}

.campaign__attributes {
  list-style: none;
  padding-inline: 0;
  margin: 0;
}

.campaign-title {
  display: flex;

  & h1 {
    margin-right: 8px;
  }

  &__tag {
    margin-right: 8px;
  }
}
