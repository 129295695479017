table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  box-sizing: border-box;
}

thead {
  width: 100%;
}

tbody {

  tr.tr--clickable {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: $grey-15;
      transition: 0.25s ease;
    }
  }
}

tr {
  width: 100%;
  height: 60px;
}

td,
th {
  text-align: left;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__column {
  width: 16%;

  &--small {
    width: 6%;
  }

  &--medium {
    width: 8%;
  }

  &--wide {
    width: 25%;
  }

  &--center {
    text-align: center;
  }

  &--id {
    width: 140px;
  }
}

.table__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  text-align: center;
  border-top: 2px solid $grey-22;
}
