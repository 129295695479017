.certification-center-information {

  h1 {
    padding-bottom: 16px;
  }

  &__display {

    &__name {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .property {
      padding: 0.5rem 1rem;
      display: block;
      border-bottom: 0.05rem solid $grey-20;

      &:last-child {
        border-bottom: none;
      }
    }

    button {
      margin: 16px 0;
    }
  }

  &__edit-form {
    width: 500px;
  }

  .field__label {
    color: $grey-70;
    font-size: 0.875rem;
    font-weight: normal;
  }

  .form-field__checkbox {
    display: flex;
    gap: 0.5rem;

    * {
      margin-bottom: 0;
      align-self: center;
    }

    input[type=checkbox] {
      width: 1rem;
      height: 1rem;
    }
  }

  .error {
    padding-top: 5px;
    color: $error;
    font-size: 0.85rem;
  }

  &__display__habilitations-list,
  &__edit-form__habilitations-checkbox-list {
    list-style-type: none;
    margin: 8px 0;
    padding: 0;
  }

  &__edit-form__habilitations-checkbox-list {
    flex-direction: column;
    display: flex;

    .habilitation-entry {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      padding-bottom: 8px;

      * {
        margin-bottom: 0;
        align-self: center;
      }

      input[type=checkbox] {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__display__habilitations-list {
    background-color: #F4F5F7;
    border-radius: 8px;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    flex-direction: row;
    padding: 8px 16px;

    .granted-habilitation-icon {
      color: $green;
    }

    .not-granted-habilitation-icon {
      color: $grey-35;
    }
  }
}
