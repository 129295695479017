.organization-all-tags-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 27px;

  &__tag {
    margin-right: 27px;
    margin-top: 21px;

    button {
      border: none;
      padding: 0;
      border-radius: 0.75rem;
      background-color: transparent;
    }
  }
}
