.tick-or-cross {

  &--valid {
    color: $success;
  }

  &--invalid {
    color: $error;
  }
}
