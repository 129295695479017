$app-sidebar-width-sm: 80px;
$app-header-height: 60px;

body > div.ember-view {
  height: 100%;
}

.app-container {
  display: flex;
  height: 100%;

  .app-sidebar {
    width: $app-sidebar-width-sm;
    min-width: $app-sidebar-width-sm;
    height: 100%;
    background-color: $grey-70;

    .app-logo {
      text-align: center;
      border-bottom: 1px solid $grey-100;
      height: $app-header-height;
      width: 100%;

      .app-logo__link {
        color: $white;
        background-color: $blue;
        display: block;
        line-height: $app-header-height;
        width: 100%;
        height: 100%;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .app-body {
    background-color: $grey-15;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .app-page {
      position: relative;
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}
