
$ember-power-select-border-color: #CED4DA;
$ember-power-select-default-border-radius: 0.25rem;
$ember-power-select-trigger-ltr-padding: 0.375rem 0.75rem !default;
$ember-power-select-line-height: 1.5 !default;

.ember-power-select-selected-item {
  margin-left: 0;
}

.ember-basic-dropdown-trigger {
  min-height: 2.375rem;
}

.ember-power-select-trigger {
  width: 150px;
  line-height: 2;
  padding-left: 2px;
}
