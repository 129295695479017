.certification-status-select {
  display: flex;

  label {
    margin-right: 10px;
  }

  select.certification-status-select__select {
    height: 30px;
  }
}
