/* Margins */

.mb_10 {
  margin-bottom: 10px;
}

/* Others */

.hidden {
  display: none;
}
