.user-detail-personal-information-section {

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;
  }

  &__buttons {
    display: flex;

    button {
      margin-right: 12px;
    }
  }
}

.schooling-registrations-table {
  margin-top: 20px;
}

.schooling-registrations-table__status {

  p {
    margin: 0;
  }

  &--isEnabled {
    color: $success;
  }

  &--isDisabled {
    color: $grey-30;
  }
}
