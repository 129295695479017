/* Structure & layout
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.modal-wrapper {
  z-index: 100;

  .modal-dialog {
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    width: 500px;
    padding: 0;
    // mandatory due to ember-boostrap modal dialog class
    pointer-events: auto;

    &__large {
      width: 600px;
    }

    .modal__container {
      position: relative;
      margin: auto;

      .modal-header {
        position: relative;
        padding: 32px 48px;
        border-bottom: 1px solid $grey-20;

        &__title {
          font-family: $font-open-sans;
          align-items: center;
          justify-content: space-between;
          color: $grey-90;
          display: flex;
          font-size: 1.6rem;
          font-weight: 600;
          margin: 0;
        }

        &__icon {
          position: absolute;
          right: 32px;
          top: 24px;
        }
      }

      .modal-body {
        padding-left: 48px;
        padding-right: 48px;
      }

      .modal-footer {
        padding: 24px 32px;
      }
    }
  }
}

/* Overlay
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.ember-modal-overlay {
  display: flex;
  justify-content: center;
  overflow: scroll;

  & > div {
    height: max-content;
    position: static;
    margin: 10vh 0;
  }
}

.ember-modal-overlay.translucent {
  background-color: rgba(red($grey-200), green($grey-200), blue($grey-200), 0.2);
}

.modal__translucent {
  background-color: transparent;
  box-shadow: none;
}
