.table-admin {

  a:not(.pix-button) {
    color: $blue;
  }

  thead label {
    margin-bottom: 0;
  }

  tbody > tr,
  thead > tr {
    border-bottom: 1px solid $grey-22;

    &:first-child {
      border-top: 1px solid $grey-22;
    }

    td,
    th {
      padding: 6px 18px;
      border-left: 1px solid $grey-22;

      &:last-child {
        border-right: 1px solid $grey-22;
      }
    }
  }

  tbody > tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .col-status {
    width: 10%;
  }
}

.table-admin__wrapper {
  overflow-x: auto;
}

.table-admin__auto-width {
  table-layout: auto;
}

.table-admin-input {
  width: 100%;
  padding: 3px 6px;
}

.table-admin-empty {
  margin: 48px auto 36px auto;
  text-align: center;
}
