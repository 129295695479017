.organization-team-section {

  &__input {
    min-width: 320px;
  }

  &__button {
    margin-left: 6px;
  }
}
