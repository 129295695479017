.target-profile-organizations {

  label {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .organization__forms-section {
    display: flex;
    justify-content: space-around;
  }

  .organization__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .organization__sub-form {
    display: flex;
    align-items: center;
    min-width: 300px;
  }

  .pix-button {
    margin-left: 8px;
  }
}
