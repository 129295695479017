.organization-information-section {

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__archived-message {
    margin-bottom: 16px;
  }

  &__details {
    overflow: hidden;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .form-actions {
      padding-bottom: 2px;
    }
  }
}
