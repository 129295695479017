/* stylelint-disable no-descending-specificity */

.candidate-edit-modal {

  &__title {
    font-family: $font-open-sans;
    padding: 12px 32px;

    h1 {
      font-size: 1.25rem;
      color: $grey-80;
      margin: 0;
      margin-bottom: 4px;
    }
  }

  &__content {
    font-size: 0.875rem;
    color: $grey-70;
    border-top: 1px solid $grey-20;
    border-bottom: 1px solid $grey-20;
    background-color: $grey-10;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 8px 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__required-fields-mention {
      width: 100%;
    }

    .required-field-indicator {
      color: $red;
    }

    &__field {
      width: 100%;
      margin: 12px 0;

      label {
        display: block;
        font-size: 0.875rem;

        &.radio-button-label {
          display: inline-block;
          margin: 0 16px 0 8px;
        }
      }

      input {
        border: 1.2px $grey-35 solid;
        box-sizing: border-box;
        width: 248px;
        height: 36px;
      }

      .pix-select {
        height: 36px;
        width: 248px;
      }

      .birth-country-selector {
        border: 1.2px $grey-35 solid;
        height: 36px;
        width: 248px;
      }

      input[type="radio"] {
        height: 20px;
        width: 20px;
      }

      .radio-button-container {
        align-items: center;
        display: flex;
        margin-top: 12px;
      }

      &-double {
        width: 248px;
      }
    }
  }

  &__actions {
    background-color: $grey-10;
    padding: 16px 0;
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 5px 5px;
    width: 100%;

    .pix-button {
      margin-left: 16px;
    }
  }
}
