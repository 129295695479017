.organization-invitations {

  &__input {
    min-width: 320px;
  }

  &__button {
    margin-left: 6px;
  }

  &__message {
    text-align: center;
  }
}
