.badges-table {

  &__id {
    width: 110px;
  }

  &__key {
    width: 150px;
  }

  &__name {
    width: 250px;
  }

  &__image {
    width: 130px;

    img {
      width: 90px;
    }
  }

  &__shortcoming {
    width: 140px;
  }

  &__actions {
    width: 160px;
  }
}

.badges-table-actions {

  &__button {
    width: 100%;

    &--delete {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }
  }
}
